import {
  BillingCycle,
  CurrencyCode,
  PaymentMethod,
  ProductType,
  SubscriptionType,
  UserWorkspace,
  WorkspaceRole,
} from "@/api/sdk"
import { Radio } from "@/components/Alpha/View/GalleryFilter"
import IconButton from "@/components/IconButton"
import Modal from "@/components/Modal"
import CryptoPaymentModal from "@/components/ModalsPortal/CryptoPaymentModal"
import { clientUrl } from "@/constants"
import { useToast } from "@/hooks"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useClearUrlQuery } from "@/hooks/useQuery"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import {
  useGetSubscriptionPreviewAmount,
  useGetUserPlanQuery,
  useSubscriptionPurchaseMutate,
  useWorkspaceMembersInfiniteQuery,
  useWorkspaceMembersMutate,
} from "@/queries"
import { isEmail } from "@/utils"
import { UseMutateFunction } from "@tanstack/react-query"
import { useWeb3Modal } from "@web3modal/wagmi/react"
import classNames from "classnames"
import { motion } from "framer-motion"
import dynamic from "next/dynamic"
import Link from "next/link"
import { Fragment, useEffect, useMemo, useState } from "react"
import { MdClose } from "react-icons/md"
import { useAccount } from "wagmi"
import { useSetting } from "../SettingProvider"
import { paymentMethods } from "../UpgradeAccount"
import { useMembers } from "./useMembers"

const Payment2C2PModal = dynamic(() => import("../Plans/Payment2C2PModal"), { ssr: false })

interface AddMemberProps {
  isOpen: boolean
  onClose: () => void
  selectedBulk: { id?: number; email: string; role: string }[]
  setSelectedBulk: (v: { id?: number; email: string; role: string }[]) => void
  setSearchDebounce: (v: string) => void
  isLimit: boolean
  setIsLimit: (v: boolean) => void
  mutateAdd: UseMutateFunction<
    UserWorkspace,
    Error,
    {
      workspaceId: string
      emails: string[]
      role: WorkspaceRole
    },
    unknown
  >
  mutateUpdateUserRole: ReturnType<typeof useMembers>["mutateUpdateUserRole"]
  isLoadingAddAccount: boolean
}

export interface AddMemberItemProps {
  email: string
  onRemove?: () => void
}

const AddMemberItem = ({ email, onRemove }: AddMemberItemProps) => {
  return (
    <motion.div
      key={email}
      className="flex items-center overflow-hidden text-xs rounded-lg bg-atherGray-800 "
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5 }}
      transition={{ duration: 0.2 }}
    >
      <div className="flex items-center px-2 py-1 min-w-max bg-atherGray-700">
        <p> {email}</p>
        <button type="button" className="ml-2 text-atherGray-300" onClick={onRemove}>
          <MdClose size={"18px"} />
        </button>
      </div>
    </motion.div>
  )
}

const roleData = [WorkspaceRole.Member, WorkspaceRole.Guest]

const AddMember = ({
  isOpen,
  onClose,
  selectedBulk,
  mutateUpdateUserRole,
  setSelectedBulk,
  setSearchDebounce,
  mutateAdd,
  isLimit,
  setIsLimit,
  isLoadingAddAccount,
}: AddMemberProps) => {
  const [value, setValue] = useState("")
  const [error, setError] = useState("")
  const [role, setRole] = useState(WorkspaceRole.Member)
  const [isConfirm, setIsConfirm] = useState(false)
  const [isPayment, setIsPayment] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethods[0].id)
  const [cryptoPayment, setCryptoPayment] = useState<{
    productType: ProductType
    productId: string
    workspaceId?: string
    subscriptionType?: SubscriptionType
    billingCycle?: BillingCycle
    seatCount?: number
    addSeatEmails?: string[]
  } | null>(null)

  const router = useCustomRouter()
  const { status } = router.query
  const { userInfoQuery, user } = useAuth()
  const toast = useToast()
  const { currentWorkspaceDetail, refetchUserPlan } = useSetting()
  const { isConnected } = useAccount()
  const { open } = useWeb3Modal()
  const clearUrl = useClearUrlQuery()

  const isEditMode = useMemo(() => selectedBulk.some(i => i.id), [selectedBulk])

  useEffect(() => {
    if (isOpen && isEditMode) {
      const role = selectedBulk.find(i => i.role)?.role

      setRole((role as WorkspaceRole) ?? WorkspaceRole.Member)
    }
  }, [isOpen])

  useEffect(() => {
    const id = setTimeout(() => {
      setSearchDebounce(value)
    }, 500)
    return () => {
      clearTimeout(id)
    }
  }, [value])

  const handleAddMemberTemp = () => {
    const inputVal = value.trim()

    if (!inputVal) {
      return
    }
    if (isEmail(inputVal)) {
      const findIndex = selectedBulk.findIndex(i => i.email === inputVal)
      if (findIndex === -1) {
        setSelectedBulk([...selectedBulk, { email: inputVal, role }])
      }
      setValue("")
      setError("")
    } else {
      setError("This email is not accepted")
    }
  }

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      handleAddMemberTemp()
    }
  }

  const {
    data: userPlan,
    isLoading,
    isSuccess,
    refetch,
  } = useGetUserPlanQuery({
    variables: { workspaceId: userInfoQuery?.data?.currentWorkspace.id ?? "" },
  })

  const { data: previewAmount } = useGetSubscriptionPreviewAmount({
    variables: {
      subscriptionPackageId: userPlan?.currentSubscription.subscriptionPackageId as string,
      workspaceId: userInfoQuery?.data?.currentWorkspace.id ?? "",
      currencyCode: CurrencyCode.USD,
      seatCount: selectedBulk.length,
      subscriptionType: SubscriptionType.AddSeat,
      billingCycle: userPlan?.currentSubscription.billingCycle as BillingCycle,
    },
    enabled: !!userPlan && isPayment,
  })

  const { total } = useWorkspaceMembersInfiniteQuery({
    variables: {
      workspaceId: currentWorkspaceDetail?.id ?? "",
      roles: [WorkspaceRole.Member, WorkspaceRole.Owner],
    },
    enabled: !!currentWorkspaceDetail,
  })

  const [paymentUrl, setPaymentUrl] = useState<string | null>(null)

  const { mutate: mutatePurchase, isPending: isMutatingPurchase } = useSubscriptionPurchaseMutate({
    onSuccess: data => {
      setPaymentUrl(data.url)
    },
    onError: error => {
      if (
        (error.message?.startsWith("Unable to invite") || error.message?.startsWith("Unable to add member")) &&
        (currentWorkspaceDetail?.subscriptionPackageId?.toLowerCase() === "free" ||
          currentWorkspaceDetail?.subscriptionPackageId?.toLowerCase() === "newbie")
      ) {
        setIsLimit(true)
        return
      }

      toast({
        title: "Error",
        message: [error.message],
        status: "error",
      })
    },
  })

  const { mutateAsync: mutateGetMembersWorkspace, isPending: isMutatingGetMembers } = useWorkspaceMembersMutate()

  const handleClose = () => {
    onClose()
    setValue("")
    setError("")
    setIsConfirm(false)
    setIsPayment(false)
    setIsLimit(false)
    setSelectedBulk([])
    setRole(WorkspaceRole.Member)
  }

  const handlePaymentMemberUser = async (mode: "add" | "edit", emails?: string[], id?: number) => {
    if (
      currentWorkspaceDetail?.subscriptionPackageId?.toLowerCase() !== "free" &&
      !isPayment &&
      role === WorkspaceRole.Member &&
      userPlan &&
      total + (emails?.length || 0) > userPlan.currentSubscription.seatCount
    ) {
      setIsPayment(true)

      return false
    }

    if (currentWorkspaceDetail?.subscriptionPackageId?.toLowerCase() !== "free" && isPayment) {
      if (!userPlan || !currentWorkspaceDetail) return

      if (mode === "add") {
        let emailExist: string[] = []

        const workspaceMembers = await mutateGetMembersWorkspace({
          take: selectedBulk.length,
          emails: selectedBulk.map(i => i.email),
          workspaceId: currentWorkspaceDetail.id ?? "",
        })

        workspaceMembers.members.forEach(i => {
          if (selectedBulk.map(i => i.email).some(j => j === i.user.email)) {
            emailExist.push(i.user.email)
          }
        })

        if (emailExist.length > 0) {
          toast({
            title: "Error",
            message: [`This email "${emailExist.join(", ")}" is already in your workspace`],
            status: "error",
          })
          return false
        }
      }

      if (selectedPaymentMethod === PaymentMethod.XSOLLA) {
        const url = `${clientUrl}/settings/account?tab=Members${
          mode === "edit" ? `&userWorkspaceId=${id}` : `&emails=${emails?.join(",")}`
        }`

        mutatePurchase({
          data: {
            subscriptionPackageId: userPlan?.currentSubscription.subscriptionPackageId,
            workspaceId: currentWorkspaceDetail?.id,
            currencyCode: CurrencyCode.VND,
            subscriptionType: SubscriptionType.AddSeat,
            frontendReturnUrl: url,
            billingCycle: userPlan?.currentSubscription.billingCycle as BillingCycle,
            seatCount: selectedBulk.length,
          },
          userUid: user?.uid ?? "",
        })
      } else if (selectedPaymentMethod === PaymentMethod.Value2C2P) {
        const url = `${clientUrl}/settings/account?tab=Members${
          mode === "edit" ? `&userWorkspaceId=${id}` : `&emails=${emails?.join(",")}`
        }`

        mutatePurchase({
          data: {
            paymentMethod: PaymentMethod.Value2C2P,
            subscriptionPackageId: userPlan?.currentSubscription.subscriptionPackageId,
            workspaceId: currentWorkspaceDetail?.id,
            currencyCode: CurrencyCode.VND,
            subscriptionType: SubscriptionType.AddSeat,
            frontendReturnUrl: url,
            billingCycle: userPlan?.currentSubscription.billingCycle as BillingCycle,
            seatCount: selectedBulk.length,
          },
          userUid: user?.uid ?? "",
        })
      } else if (selectedPaymentMethod === PaymentMethod.CRYPTO) {
        if (!isConnected) {
          open()
          return
        }

        setCryptoPayment({
          productType: ProductType.Subscription,
          productId: userPlan?.currentSubscription.subscriptionPackageId,
          workspaceId: currentWorkspaceDetail?.id,
          subscriptionType: SubscriptionType.AddSeat,
          billingCycle: userPlan?.currentSubscription.billingCycle as BillingCycle,
          seatCount: selectedBulk.length,
          addSeatEmails: emails,
        })
      } else if (selectedPaymentMethod === PaymentMethod.STRIPE) {
        const url = `${clientUrl}/settings/account?tab=Members${
          mode === "edit" ? `&userWorkspaceId=${id}` : `&emails=${emails?.join(",")}`
        }`
        mutatePurchase(
          {
            data: {
              paymentMethod: PaymentMethod.STRIPE,
              subscriptionPackageId: userPlan?.currentSubscription.subscriptionPackageId,
              workspaceId: currentWorkspaceDetail?.id,
              currencyCode: CurrencyCode.USD,
              subscriptionType: SubscriptionType.AddSeat,
              frontendReturnUrl: url,
              billingCycle: userPlan?.currentSubscription.billingCycle as BillingCycle,
              seatCount: selectedBulk.length,
            },
            userUid: user?.uid ?? "",
          },
          {
            onSettled: async () => {
              setIsPayment(false)
              await refetchUserPlan()
              router.push(url)
            },
          },
        )
      }

      if (isPayment) {
        setIsPayment(false)
      }

      return false
    }

    return true
  }

  const handleClick = async () => {
    if (!userPlan || !currentWorkspaceDetail) return

    if (isLimit) {
      router.push("/settings/account?tab=Plans")
      return
    }

    if (isEditMode) {
      if (role === WorkspaceRole.Guest && !isConfirm) {
        setIsConfirm(true)
        return
      }

      if (selectedBulk.find(i => i.role === role)) {
        handleClose()
        return
      }

      const listID = selectedBulk.find(i => i.id)?.id

      if (!listID) return

      const result = await handlePaymentMemberUser("edit", undefined, listID)

      if (!result) return

      mutateUpdateUserRole(
        {
          id: listID,
          role,
        },
        {
          onSuccess: () => {
            if (isConfirm) {
              setIsConfirm(false)
            }
            handleClose()
          },
        },
      )

      googleAnalytics.event({
        action: "click",
        category: "settings_account",
        label: `edit workspace's member`,
        params: {
          user_id: userInfoQuery?.data?.uid ?? "",
          user_username: userInfoQuery?.data?.username ?? "",
          workspace_id: currentWorkspaceDetail?.id ?? "",
          user_added_id: selectedBulk.map(i => i.id).toString(),
          user_role: role,
        },
        value: 1,
      })

      return
    }

    const listID = selectedBulk.map(i => i.email)

    const result = await handlePaymentMemberUser("add", listID)

    if (!result) return

    mutateAdd({
      workspaceId: currentWorkspaceDetail?.id ?? "",
      emails: listID,
      role,
    })

    googleAnalytics.event({
      action: "click",
      category: "settings_account",
      label: `add workspace's member`,
      params: {
        user_id: userInfoQuery?.data?.uid ?? "",
        user_username: userInfoQuery?.data?.username ?? "",
        workspace_id: currentWorkspaceDetail?.id ?? "",
        user_role: role,
        user_added_id: listID.toString(),
      },
      value: 1,
    })
  }

  const renderUI = () => {
    if (isPayment)
      return (
        <Fragment>
          <div>
            <div className="relative flex items-center justify-between w-full mb-2">
              <div>
                <p className="font-semibold">&quot;Started&quot; plan</p>
                <div className="text-atherGray-300">
                  x{selectedBulk.length} {selectedBulk.length > 1 ? "seats" : "seat"}
                </div>
              </div>
              <div className="flex flex-col items-end">
                <p className="">${previewAmount?.amountPerSeat ?? 0}</p>
                <div className="text-atherGray-300">user/{userPlan?.currentSubscription?.billingCycle ?? "month"}</div>
              </div>
            </div>
            <div className="border-t border-atherGray-800 flex items-center justify-between text-base pt-2">
              <p className="font-semibold">Total</p>
              <p className="font-semibold">${previewAmount?.amount ?? 0}</p>
            </div>
          </div>
          <div>
            <p className="font-semibold text-xs text-atherGray-300 mb-2">Payment method</p>
            <div className="border-t border-atherGray-800 flex flex-col space-y-2 text-base pt-2">
              {paymentMethods
                .filter(method => method.id === userPlan?.currentSubscription.paymentMethod)
                .map((method, index) => (
                  <Fragment key={method.id}>
                    {index !== 0 && <hr className="border-atherGray-800" />}
                    <div
                      className={classNames("py-2", {
                        "opacity-40 cursor-not-allowed": method.isDisabled,
                      })}
                    >
                      <Radio
                        name={method.name}
                        labelClassName={"text-base font-semibold"}
                        containerClassName={classNames({
                          "cursor-not-allowed": method.isDisabled,
                        })}
                        value={method.id}
                        leftIcon={<img src={method.icon} alt="" className="w-8 h-8 ml-4" />}
                        checked={selectedPaymentMethod === method.id}
                        onChange={() => {
                          if (method.isDisabled) return
                          setSelectedPaymentMethod(method.id)
                        }}
                      />
                    </div>
                  </Fragment>
                ))}
            </div>
          </div>
        </Fragment>
      )

    return (
      <>
        <div className="relative flex flex-col w-full ">
          <label className="mb-1 font-normal text-atherGray-300">Email Address</label>
          <div className="flex items-start mb-1">
            <div
              className={classNames(
                "flex gap-1 p-2 flex-wrap rounded-lg flex-1 placeholder:font-normal placeholder:text-atherGray-500 bg-atherGray-800 max-h-[100px] overflow-auto",
                {
                  "pointer-events-none max-h-full": isEditMode,
                },
              )}
            >
              {selectedBulk.map((i, index) => (
                <AddMemberItem
                  key={i.email + index}
                  email={i.email}
                  onRemove={() => {
                    const temp = [...selectedBulk]
                    const findIndex = selectedBulk.findIndex(_i => _i === i)

                    temp.splice(findIndex, 1)
                    setSelectedBulk(temp)
                  }}
                />
              ))}
              {!isEditMode && (
                <input
                  className="flex-1 bg-transparent p-1 rounded-lg"
                  id="email_address"
                  type="email"
                  value={value}
                  placeholder={selectedBulk.length === 0 ? "Enter email address..." : ""}
                  onChange={v => setValue(v.target.value)}
                  onKeyDown={handleKeyDown}
                />
              )}
            </div>
            {!isEditMode && (
              <IconButton className="ml-2" onClick={handleAddMemberTemp}>
                Add
              </IconButton>
            )}
          </div>
          {isEditMode && selectedBulk.some(i => i.role === WorkspaceRole.Guest) && (
            <p className="italic text-red-500">
              This user has the &quot;Guest&quot; role in your workspace. To allow them to be able to create and
              collaborate with you actively. You can change their role to become a member of your workspace.
              <br />
              <br />
              Note: Each member under your workspace will be charged the subscription same as you monthly.
            </p>
          )}
          {!!error && <p className="text-[11px] pl-2 text-red-500">{error}</p>}
        </div>
        <div>
          <p className="mb-4 text-atherGray-300">Role</p>
          <div className="flex items-center space-x-8">
            {roleData.map(i => (
              <div key={i}>
                <Radio
                  labelClassName="capitalize"
                  name={i}
                  checked={role === i}
                  value={i}
                  onChange={() => setRole(i)}
                />
              </div>
            ))}
          </div>
        </div>
      </>
    )
  }

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        key={isLimit || isConfirm ? "confirm" : "add"}
        title={isPayment ? "Add subscription plan" : isEditMode ? "Edit member" : "Add members"}
        className="max-w-lg overflow-visible"
      >
        {isLimit ? (
          <p className="text-atherGray-300">
            You have reached the limit of adding members to{" "}
            <span className="text-atherGray-0 font-semibold">
              {userPlan?.currentSubscription.subscriptionPackageName}
            </span>
            . Upgrade now to add more members.
          </p>
        ) : isConfirm ? (
          <p>
            Do you want to convert this Workspace Member to a Guest ? <br />
            As soon as the user is converted to a Guest, they are removed from the subscription and we will release a
            seat (license) to your workspace.
            <br />
            <br />
            Guest just can view only.
            <br />
            <br />
            And If the user owns any images, folders, styles, macros; all items will be reassigned to the corresponding
            Workspace Owner. The user will still have access to any items they were invited to over email as a Guest
            with view permission.
          </p>
        ) : (
          <div className="space-y-6">
            {!isEditMode && !isPayment && (
              <p className="font-normal text-atherGray-300">
                Bring your whole team to collaborate and unlock the full power of GAIA together.
              </p>
            )}
            {!isEditMode && isPayment && (
              <p className="font-normal text-atherGray-300">
                Payment for {selectedBulk.length} seats will be added to your{" "}
                {userPlan?.currentSubscription.subscriptionPackageName} renewal invoice.
              </p>
            )}
            {renderUI()}
            <div className="">
              <p className="font-normal text-atherGray-300">
                {currentWorkspaceDetail?.subscriptionPackageId?.toLowerCase() === "free" ||
                currentWorkspaceDetail?.subscriptionPackageId?.toLowerCase() === "newbie" ? (
                  <>
                    *You&apos;re using {userPlan?.currentSubscription?.subscriptionPackageName} plan for maximum{" "}
                    <span className="font-semibold text-atherGray-0">
                      {total}/{userPlan?.currentSubscription?.maxMembers || "3"}
                    </span>{" "}
                    members added to your workspace. To add more{" "}
                    <Link href="/settings/account?tab=Plans" className="font-semibold underline text-atherGray-0">
                      Upgrade your plan.
                    </Link>
                  </>
                ) : (
                  <>
                    {selectedPaymentMethod === PaymentMethod.STRIPE && (
                      <p>* Your next payment will include the prorated cost of new seats</p>
                    )}
                    * You&apos;re using {userPlan?.currentSubscription?.subscriptionPackageName} plan for maximum{" "}
                    <span className="font-semibold text-atherGray-0">
                      {total}/{userPlan?.currentSubscription?.maxMembers || "20"}
                    </span>{" "}
                    members added to your workspace.{" "}
                    <p>
                      * Available seats:{" "}
                      <span className="font-semibold text-atherGray-0">
                        {total}/{userPlan?.currentSubscription?.seatCount}
                      </span>
                    </p>
                    To add more{" "}
                    <Link href="/settings/account?tab=Plans" className="font-semibold underline text-atherGray-0">
                      Upgrade your plan.
                    </Link>
                  </>
                )}
              </p>
            </div>
          </div>
        )}
        <div className="flex justify-end w-full space-x-2 mt-8">
          <IconButton colorScheme="secondary" onClick={handleClose}>
            Cancel
          </IconButton>
          <IconButton
            disabled={selectedBulk?.length === 0}
            isLoading={isLoadingAddAccount || isMutatingPurchase || isMutatingGetMembers}
            onClick={() => handleClick()}
          >
            {isLimit
              ? "Upgrade now"
              : isEditMode
                ? `${isConfirm ? "Complete" : "Update"}`
                : currentWorkspaceDetail?.subscriptionPackageId?.toLowerCase() !== "free"
                  ? isPayment
                    ? selectedPaymentMethod === PaymentMethod.STRIPE
                      ? "Update subscription"
                      : "Process to Payment"
                    : "Add members"
                  : "Send invitation"}
          </IconButton>
        </div>
      </Modal>

      <Payment2C2PModal
        onSuccess={() => {
          router.replace("/settings/account?tab=Members")
        }}
        isOpen={paymentUrl}
        onClose={() => {
          setPaymentUrl(null)
        }}
      />

      {cryptoPayment && (
        <CryptoPaymentModal
          cryptoPayment={cryptoPayment}
          onClose={() => setCryptoPayment(null)}
          onSuccessfulPayment={() => handleClose()}
        />
      )}
    </Fragment>
  )
}

export default AddMember
