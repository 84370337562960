import { PaymentMethod, PaymentPlatform, WorkspacePaymentInfoItem } from "@/api/sdk"
import { Radio } from "@/components/Alpha/View/GalleryFilter"
import IconButton from "@/components/IconButton"
import Modal from "@/components/Modal"
import { useAuth } from "@/providers/authContext"
import { useGetPaymentInfoQuery, useUpdatePaymentMethodMutation, useUpdateStripePaymentMutation } from "@/queries"
import { useState } from "react"
import { paymentMethods } from "../UpgradeAccount"

interface BillingInfoModalProps {
  isOpen: boolean
  onClose: () => void
  onPaymentUrl?: (url: string) => void
  onPaymentSecret?: (secret: string | null) => void
}

const platformMethodMap = {
  [PaymentPlatform.STRIPE]: PaymentMethod.STRIPE,
  [PaymentPlatform.Value2C2P]: PaymentMethod.Value2C2P,
}

const BillingInfoModal = ({ isOpen, onClose, onPaymentUrl, onPaymentSecret }: BillingInfoModalProps) => {
  const { userInfoQuery } = useAuth()

  const { data: workspacePaymentInfo = [] } = useGetPaymentInfoQuery({
    variables: {
      workspaceId: userInfoQuery?.data?.currentWorkspace.id ?? "",
    },
  })

  const { mutate: mutateUpdatePaymentMethod, isPending: isUpdating } = useUpdatePaymentMethodMutation({
    onSuccess: data => {
      onPaymentUrl?.(data.url)
      onPaymentSecret?.(data.clientSecret as string)
    },
  })

  const { mutate: mutateUpdateStripePaymentMethod, isPending: isUpdatingStripe } = useUpdateStripePaymentMutation({
    onSuccess: data => {
      onPaymentUrl?.(data.url)
      onPaymentSecret?.(data.clientSecret as string)
    },
  })

  const [selectedPaymentInfoItem, setPaymentInfoItem] = useState<WorkspacePaymentInfoItem>(workspacePaymentInfo?.[0])
  return (
    <Modal title="Update Card Detail" isOpen={isOpen} onClose={onClose}>
      <div>
        <p className="text-atherGray-300">
          This applies to fiat payments with 2C2P. When you change your card information (change to a new card), you
          will be charged a small fee (1 USD) to make this request.
        </p>
        <p className="text-atherGray-300">This amount will be refunded to your account afterwards.</p>
        <div className="flex flex-col space-y-4 mt-4">
          {workspacePaymentInfo.map(item => (
            <div className="flex items-center justify-between" key={item.id}>
              <Radio
                checked={item.id === selectedPaymentInfoItem?.id}
                onChange={() => {
                  setPaymentInfoItem(item)
                }}
                value={item.id}
                name={item.creditCard}
                leftIcon={
                  <div className="ml-4 flex items-center">
                    <div className="rounded-md overflow-hidden">
                      <img
                        src={paymentMethods.find(method => method.id === platformMethodMap[item.platform])?.icon}
                        alt=""
                        className="w-8 h-8"
                      />
                    </div>
                  </div>
                }
              />
            </div>
          ))}
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end space-x-2">
        <IconButton colorScheme="secondary" onClick={onClose}>
          Cancel
        </IconButton>
        <IconButton
          disabled={workspacePaymentInfo.length === 0 && !selectedPaymentInfoItem}
          isLoading={isUpdating && isUpdatingStripe}
          onClick={() => {
            if (selectedPaymentInfoItem?.platform === PaymentPlatform.Value2C2P) {
              mutateUpdatePaymentMethod({
                subscriptionId: userInfoQuery?.data?.subscription.id ?? "",
                data: {
                  paymentMethod: PaymentMethod.Value2C2P,
                },
              })
              return
            }
            if (selectedPaymentInfoItem?.platform === PaymentPlatform.STRIPE) {
              mutateUpdateStripePaymentMethod({ subscriptionId: userInfoQuery?.data?.subscription.id ?? "" })
              return
            }
          }}
        >
          Update Now
        </IconButton>
      </div>
    </Modal>
  )
}

export default BillingInfoModal
